<template>
  <div class="text-center">
    <v-menu
      v-if="activeLayers"
      v-model="opacityMenu"
      :close-on-content-click="false"
      :nudge-width="100"
      dark
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-blur
          min-height="40"
          min-width="40"
          text
          v-bind="attrs"
          :disabled="!activeLayers"
          v-on="on"
        >
          <SvgIcon
            :title="$t('ServiceCard.transparency')"
            :active="activeLayers"
            :path="opacitySvg"
          />
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t('ServiceCard.transparency') }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <SvgIcon
                :active="false"
                :title="$t('ServiceCard.transparency')"
                :path="opacitySvg"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <v-slider
          v-model="sliderOpacityValue"
          class="my-0 mx-2"
          hide-details
          color="green"
          track-color="green"
          thumb-label
          max="100"
          min="0"
          @input="setLayerOpacity()"
          @mouseup="opacityMenu = false;"
        >
          <template v-slot:thumb-label="{ value }">
            {{ value + "%" }}
          </template>
        </v-slider>

        <div class="sliderLabel mx-3">
          <p>0</p><p>100</p>
        </div>
      </v-card>
    </v-menu>

    <v-btn
      v-else
      min-height="40"
      min-width="40"
      text
      :disabled="!activeLayers"
    >
      <SvgIcon
        :title="$t('ServiceCard.transparency')"
        :active="activeLayers"
        :path="opacitySvg"
      />
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SvgIcon from '@/components/utils/SvgIcon.vue';
import { svgOpacity } from '@/svg.js';

export default {
  components: {
    SvgIcon
  },
  props: {
    serviceCardId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      opacityMenu: false,
      sliderOpacityValue: 60,
      opacitySvg: svgOpacity
    };
  },
  computed: {
    ...mapState(['map']),
    activeLayers() {
      return this.map.OLmap.getLayers().getArray().some((layer) => layer.values_.service_id === this.serviceCardId);
    }
  },
  watch: {
    $route() {}
  },
  mounted() {
  },
  methods: {
    ...mapActions([]),
    setLayerOpacity() {
      const layers = this.map.OLmap.getLayers().getArray().slice(1);
      layers.forEach(layer => {
        if (layer.values_.service_id == this.serviceCardId) {
          const newValue = this.sliderOpacityValue / 100;
          layer.setOpacity(newValue);
        }
      });
    }
  }
};
</script>

<style scoped>
.sliderLabel {
  display: flex;
  justify-content: space-between;
}
</style>
